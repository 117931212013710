<template>
  <div class="column content-box">
    <header class="header">
      <div class="table-actions-wrap">
        <div class="right-aligned-wrap">
          <div class="search-wrap">
            <fluent-icon icon="search" class="search-icon" />
            <input
              type="text"
              :placeholder="$t('AGENT_MGMT.SEARCH_INPUT_PLACEHOLDER')"
              class="contact-search"
              :value="searchQuery"
              @keyup.enter="onSearchSubmit"
              @input="onInputSearch"
            />
            <woot-button
              :is-loading="false"
              class="clear"
              :class-names="searchButtonClass"
              @click="onSearchSubmit"
            >
              {{ $t('AGENT_MGMT.SEARCH_BUTTON') }}
            </woot-button>
          </div>
        </div>
      </div>
    </header>

    <woot-button
      color-scheme="success"
      class-names="button--fixed-right-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('AGENT_MGMT.HEADER_BTN_TXT') }}
    </woot-button>

    <!-- List Agents -->
    <div class="row">
      <div class="small-8 columns with-right-space ">
        <section class="agents-table-wrap">
          <ve-table
            :fixed-header="true"
            max-height="calc(90vh - 11.4rem)"
            scroll-width="90rem"
            :columns="columns"
            :table-data="tableData"
            :border-around="false"
            :sort-option="sortOption"
          />
          <empty-state
            v-if="!isLoading && !agents.length"
            :title="$t('AGENT_MGMT.LIST.404')"
          />
          <div v-if="isLoading" class="agents--loader">
            <spinner />
            <span>{{ $t('AGENT_MGMT.LIST.LOADING_MESSAGE') }}</span>
          </div>
          <table-footer
            v-if="agents.length > 0"
            :current-page="currentPage"
            :total-count="meta.count"
            :page-size="15"
            @page-change="onPageChange"
          />
        </section>
      </div>
      <div class="small-4 columns">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('AGENT_MGMT.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>

    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-agent :on-close="hideAddPopup" :enabled-features="enabledFeatures" />
    </woot-modal>
    <!-- Edit Agent -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-agent
        v-if="showEditPopup"
        :id="currentAgent.id"
        :name="currentAgent.name"
        :type="currentAgent.role"
        :email="currentAgent.email"
        :phone="currentAgent.phone_number"
        :extension="currentAgent.phone_extension"
        :on-close="hideEditPopup"
        :enabled-features="enabledFeatures"
      />
    </woot-modal>
    <!-- Delete Agent -->
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('AGENT_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import Thumbnail from '../../../../components/widgets/Thumbnail';
import AddAgent from './AddAgent';
import EditAgent from './EditAgent';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon';
import TableFooter from 'dashboard/components/widgets/TableFooter.vue'; // Importa TableFooter
import { AGENTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';

export default {
  components: {
    AddAgent,
    EditAgent,
    Thumbnail,
    EmptyState,
    VeTable,
    Spinner,
    FluentIcon,
    TableFooter,
  },
  mixins: [globalConfigMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      agentAPI: {
        message: '',
      },
      currentAgent: {},
      enabledFeatures: {},
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.onSortChange(params),
      },
      searchQuery: '',
      currentPage: 1,
    };
  },
  computed: {
    /**
     * Devuelve los datos de la tabla formateados para ser mostrados.
     *
     * @returns {Array} - Array de objetos que representan los datos de los agentes.
     */
    tableData() {
      const locale = this.$root.$i18n.locale;

      if (this.isLoading) {
        return [];
      }
      return this.agents.map(item => {
        // Nota: Los atributos usados aquí están en snake case
        // lo que simplifica el cálculo del atributo de ordenamiento
        const { updated_at: updatedAt } = item;
        const { created_at: createdAt } = item;
        return {
          ...item,
          last_activity_at: updatedAt
            ? this.dynamicTime(updatedAt, locale)
            : '---',
          created_at: createdAt ? this.dynamicTime(createdAt, locale) : '---',
        };
      });
    },

    /**
     * Define las columnas de la tabla que mostrarán la información de los agentes.
     *
     * @returns {Array} - Array de objetos que representan las columnas de la tabla.
     */
    columns() {
      return [
        {
          field: 'name',
          key: 'name',
          title: this.$t('AGENT_MGMT.LIST.NAME'),
          fixed: 'left',
          align: 'left',
          sortBy: this.sortConfig.name || '',
          width: 300,
          renderBodyCell: ({ row }) => (
            <woot-button variant="clear" class="contactName-button">
              <div class="row--user-block">
                <Thumbnail
                  src={row.thumbnail}
                  size="32px"
                  username={row.name}
                  status={row.availability_status}
                />
                <div class="user-block">
                  <h6 class="sub-block-title text-truncate user-name">
                    {row.name}
                  </h6>
                </div>
              </div>
            </woot-button>
          ),
        },
        {
          field: 'email',
          key: 'email',
          title: this.$t('AGENT_MGMT.LIST.EMAIL'),
          align: 'left',
          sortBy: this.sortConfig.email || '',
          width: 240,
          renderBodyCell: ({ row }) => (
            <div class="text-truncate">{row.email ? row.email : '---'}</div>
          ),
        },
        {
          field: 'phone_extension',
          key: 'phone_extension',
          sortBy: this.sortConfig.phone_extension || '',
          title: this.$t('AGENT_MGMT.LIST.EXTENSION'),
          align: 'left',
        },
        {
          field: 'role',
          key: 'role',
          sortBy: this.sortConfig.role || '',
          title: this.$t(`AGENT_MGMT.LIST.TYPE`),
          align: 'left',
          renderBodyCell: ({ row }) => (
            <span class="agent-name">
              {this.$t(
                `AGENT_MGMT.AGENT_TYPES.${
                  row.role ? row.role.toUpperCase() : ''
                }`
              )}
            </span>
          ),
        },
        {
          field: 'confirmed',
          key: 'confirmed',
          sortBy: this.sortConfig.confirmed || '',
          title: this.$t('AGENT_MGMT.LIST.VERIFIED'),
          align: 'left',
          renderBodyCell: ({ row }) => (
            <span>
              {row.confirmed ? (
                <span>{this.$t('AGENT_MGMT.LIST.VERIFIED')}</span>
              ) : (
                <span>{this.$t('AGENT_MGMT.LIST.VERIFICATION_PENDING')}</span>
              )}
            </span>
          ),
        },
        {
          field: 'actions',
          key: 'actions',
          title: this.$t('AGENT_MGMT.LIST.ACTIONS'),
          align: 'left',
          renderBodyCell: ({ row, index }) => (
            <div class="button-wrapper">
              <woot-button
                title={this.$t('AGENT_MGMT.EDIT.BUTTON_TEXT')}
                variant="smooth"
                size="tiny"
                color-scheme="secondary"
                icon="edit"
                class-names="grey-btn"
                onClick={() => this.openEditPopup(row)}
              />
              {this.showDeleteAction(row) && (
                <woot-button
                  title={this.$t('AGENT_MGMT.DELETE.BUTTON_TEXT')}
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  isLoading={row.id && this.loading[row.id]}
                  onClick={() => this.openDeletePopup(row, index)}
                />
              )}
            </div>
          ),
        },
      ];
    },
    ...mapGetters({
      agents: 'agents/getAgents',
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
      meta: 'agents/getMeta',
    }),
    account() {
      return this.$store.getters['accounts/getAccount'](this.accountId);
    },
    deleteConfirmText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.YES')} ${
        this.currentAgent.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.NO')} ${
        this.currentAgent.name
      }`;
    },
    deleteMessage() {
      return ` ${this.currentAgent.name}?`;
    },
    /**
     * Verifica si se está realizando una solicitud de carga.
     *
     * @returns {boolean} - Retorna verdadero si se está obteniendo información, de lo contrario, falso.
     */
    isLoading() {
      return this.uiFlags.isFetching;
    },

    /**
     * Determina la clase CSS del botón de búsqueda.
     *
     * @returns {string} - Devuelve 'show' si la consulta de búsqueda no está vacía; de lo contrario, devuelve una cadena vacía.
     */
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
  },
  watch: {
    /**
     * Establece la configuración de ordenamiento.
     */
    sortOrder() {
      this.setSortConfig();
    },

    /**
     * Establece la configuración de ordenamiento.
     */
    sortParam() {
      this.setSortConfig();
    },
  },
  mounted() {
    this.fetchAgents(this.currentPage);
    this.initializeEnabledFeatures();
    this.setSortConfig();
  },
  methods: {
    /**
     * Maneja el cambio de ordenamiento en la tabla de agentes.
     * Actualiza la configuración de ordenamiento y realiza una nueva búsqueda de agentes.
     * Registra el evento de aplicación de ordenamiento.
     *
     * @param {Object} params - Parámetros de ordenamiento que contienen las columnas y su orden.
     */
    onSortChange(params) {
      this.sortConfig = params;
      this.fetchAgents(this.currentPage);

      const sortBy =
        Object.entries(params).find(pair => Boolean(pair[1])) || [];

      this.$track(AGENTS_EVENTS.APPLY_SORT, {
        appliedOn: sortBy[0],
        order: sortBy[1],
      });
    },

    /**
     * Actualiza el parámetro de la página en la URL.
     *
     * @param {number} page - El número de página a establecer en la URL.
     */
    updatePageParam(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
    },

    /**
     * Recupera la lista de agentes, actualizando la página y los parámetros de búsqueda si es necesario.
     *
     * @param {number} page - Número de página a recuperar.
     */
    fetchAgents(page) {
      this.updatePageParam(page);
      let value = '';
      if (this.searchQuery.charAt(0) === '+') {
        value = this.searchQuery.substring(1);
      } else {
        value = this.searchQuery;
      }
      const requestParams = {
        page,
        sortAttr: this.getSortAttribute(),
        label: this.label,
      };
      if (!value) {
        this.$store.dispatch('agents/get', requestParams);
      } else {
        this.$store.dispatch('agents/search', {
          search: encodeURIComponent(value),
          ...requestParams,
        });
      }
    },

    /**
     * Establece la configuración de ordenamiento basado en los parámetros de orden y atributo.
     */
    setSortConfig() {
      this.sortConfig = { [this.sortParam]: this.sortOrder };
    },
    showEditAction(agent) {
      return this.currentUserId !== agent.id;
    },
    showDeleteAction(agent) {
      if (this.currentUserId === agent.id) {
        return false;
      }

      if (!agent.confirmed) {
        return true;
      }

      if (agent.role === 'administrator') {
        return this.verifiedAdministrators().length !== 1;
      }
      return true;
    },
    /**
     * Filtra la lista de agentes para obtener solo aquellos que son administradores
     * verificados (confirmados).
     *
     * @returns {Array} - Lista de agentes que son administradores confirmados.
     */
    verifiedAdministrators() {
      return this.agents.filter(
        agent => agent.role === 'administrator' && agent.confirmed
      );
    },

    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Function
    openEditPopup(agent) {
      this.showEditPopup = true;
      this.currentAgent = agent;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Function
    openDeletePopup(agent) {
      this.showDeletePopup = true;
      this.currentAgent = agent;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentAgent.id] = true;
      this.closeDeletePopup();
      this.deleteAgent(this.currentAgent.id);
    },
    async deleteAgent(id) {
      try {
        await this.$store.dispatch('agents/delete', id);
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.DELETE.API.ERROR_MESSAGE'));
      }
    },
    // Show SnackBar
    showAlert(message) {
      // Reset loading, current selected agent
      this.loading[this.currentAgent.id] = false;
      this.currentAgent = {};
      // Show message
      this.agentAPI.message = message;
      bus.$emit('newToastMessage', message);
    },
    /**
     * Obtiene los features habilitados para el cliente
     */
    async initializeEnabledFeatures() {
      await this.$store.dispatch('accounts/get', this.accountId);
      this.enabledFeatures = this.account.features;
    },
    /**
     * Obtiene el atributo de ordenamiento basado en la configuración actual.
     * Si no hay configuración de orden, establece 'email' como el atributo predeterminado en orden descendente.
     *
     * @returns {string} - El atributo de ordenamiento en formato adecuado.
     */
    getSortAttribute() {
      let sortAttr = Object.keys(this.sortConfig).reduce((acc, sortKey) => {
        const sortOrder = this.sortConfig[sortKey];
        if (sortOrder) {
          const sortOrderSign = sortOrder === 'asc' ? '' : '-';
          return `${sortOrderSign}${sortKey}`;
        }
        return acc;
      }, '');
      if (!sortAttr) {
        this.sortConfig = { email: 'desc' };
        sortAttr = '-email';
      }
      return sortAttr;
    },

    /**
     * Maneja la entrada del campo de búsqueda, actualizando la consulta de búsqueda.
     * Si el nuevo valor está vacío y había una búsqueda previa, vuelve a recuperar todos los agentes.
     *
     * @param {Event} event - Evento del input que contiene el nuevo valor de búsqueda.
     */
    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllAgents = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;
      if (refetchAllAgents) {
        this.fetchAgents(1);
      }
    },

    /**
     * Maneja la sumisión del formulario de búsqueda.
     * Si hay una consulta de búsqueda, recupera la lista de agentes.
     */
    onSearchSubmit() {
      if (this.searchQuery) {
        this.fetchAgents(1);
      }
    },

    /**
     * Maneja el cambio de página, actualizando el número de página actual y recuperando los agentes correspondientes.
     *
     * @param {number} page - El número de página a establecer y recuperar.
     */
    onPageChange(page) {
      this.currentPage = page; // Actualiza currentPage en el data
      this.fetchAgents(page); // Llama a fetchAgents para actualizar la tabla
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.header {
  position: fixed;
  right: 12.8rem;
  top: 0.4rem;
}
.phone-extension {
  /* Establece un ancho mínimo para garantizar que siempre ocupe espacio */
  min-width: 50px; /* ajusta según sea necesario */
  display: inline-block;

  /* Si está vacío, se usa un espacio en blanco invisible */
  &:empty::before {
    content: '\00a0'; /* espacio no separable */
    display: inline-block;
    width: 100%; /* asegura que el espacio ocupe el ancho completo */
  }
}

.agents-table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.agents-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }
  .row--user-block {
    align-items: center;
    display: flex;
    text-align: left;

    .user-block {
      min-width: 0;
    }

    .user-thumbnail-box {
      margin-right: var(--space-small);
    }

    .user-name {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      margin: 0;
      text-transform: capitalize;
    }

    .view-details--button {
      color: var(--color-body);
    }

    .user-email {
      margin: 0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }
}

.agents--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.cell--social-profiles {
  a {
    color: var(--s-300);
    display: inline-block;
    font-size: var(--font-size-medium);
    min-width: var(--space-large);
    text-align: center;
  }
}

.contactName-button {
  width: 100%;
}

.page-title {
  margin: 0;
}
.table-actions-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--space-small) var(--space-normal) var(--space-small)
    var(--space-normal);
}

.left-aligned-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-aligned-wrap {
  display: flex;
}

.search-wrap {
  max-width: 400px;
  min-width: 150px;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: var(--space-small);
  margin-left: var(--space-small);

  .search-icon {
    position: absolute;
    top: 1px;
    left: var(--space-one);
    height: 3.8rem;
    line-height: 3.6rem;
    font-size: var(--font-size-medium);
    color: var(--b-700);
  }
  .contact-search {
    margin: 0;
    height: 3.8rem;
    width: 100%;
    padding-left: var(--space-large);
    padding-right: 6rem;
    border-color: var(--s-100);
  }

  .button {
    margin-left: var(--space-small);
    height: 3.2rem;
    right: var(--space-smaller);
    position: absolute;
    padding: 0 var(--space-small);
    transition: transform 100ms linear;
    opacity: 0;
    transform: translateX(-1px);
    visibility: hidden;
  }

  .button.show {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}
.filters__button-wrap {
  position: relative;

  .filters__applied-indicator {
    position: absolute;
    height: var(--space-small);
    width: var(--space-small);
    top: var(--space-smaller);
    right: var(--space-slab);
    background-color: var(--s-500);
    border-radius: var(--border-radius-rounded);
  }
}
</style>
