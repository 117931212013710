import * as types from '../mutation-types';
import AgentAPI from '../../api/agents';
import Vue from 'vue';

export const state = {
  records: {},
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  meta: {
    currentPage: 1,
    count: 0,
  },
  appliedFilters: [],
  sortOrder: [],
};

export const getters = {
  /**
   * Obtiene todos los agentes en el orden especificado.
   * @param {Object} $state - El estado actual del módulo.
   * @returns {Array} - Un arreglo de agentes en el orden definido por `sortOrder`.
   */
  getAgents($state) {
    return $state.sortOrder.map(agentId => $state.records[agentId]);
  },

  /**
   * Obtiene el estado de la interfaz de usuario.
   * @param {Object} $state - El estado actual del módulo.
   * @returns {Object} - El objeto que representa los indicadores de estado de la interfaz de usuario.
   */
  getUIFlags($state) {
    return $state.uiFlags;
  },

  /**
   * Obtiene un agente específico por su ID.
   * @param {Object} $state - El estado actual del módulo.
   * @param {string} id - El ID del agente a obtener.
   * @returns {Object} - El objeto del agente correspondiente al ID, o un objeto vacío si no se encuentra.
   */
  getAgent: $state => id => {
    const agent = $state.records[id];
    return agent || {};
  },

  /**
   * Obtiene los metadatos de la paginación.
   * @param {Object} $state - El estado actual del módulo.
   * @returns {Object} - El objeto que contiene los metadatos de paginación.
   */
  getMeta: $state => {
    return $state.meta;
  },

  /**
   * Obtiene los filtros aplicados a los agentes.
   * @param {Object} _state - El estado actual del módulo.
   * @returns {Array} - Un arreglo de los filtros aplicados.
   */
  getAppliedAgentFilters: _state => {
    return _state.appliedFilters;
  },

  /**
   * Obtiene los agentes verificados.
   * @param {Object} $state - El estado actual del módulo.
   * @returns {Array} - Un arreglo de agentes que están confirmados.
   */
  getVerifiedAgents($state) {
    return Object.values($state.records).filter(record => record.confirmed);
  },

  /**
   * Obtiene el estado de disponibilidad de los agentes.
   * @param {Object} $state - El estado actual del módulo.
   * @returns {Object} - Un objeto que contiene el conteo de agentes según su estado de disponibilidad (online, busy, offline).
   */
  getAgentStatus($state) {
    let status = {
      online: Object.values($state.records).filter(
        agent => agent.availability_status === 'online'
      ).length,
      busy: Object.values($state.records).filter(
        agent => agent.availability_status === 'busy'
      ).length,
      offline: Object.values($state.records).filter(
        agent => agent.availability_status === 'offline'
      ).length,
    };
    return status;
  },
};

export const actions = {
  /**
   * Obtiene una lista de agentes con paginación y ordenación.
   * @param {Object} context - El contexto del módulo que contiene `commit` y `state`.
   * @param {Object} params - Los parámetros para la solicitud.
   * @param {number} [params.page=1] - El número de página a obtener.
   * @param {string} [params.sortAttr] - Atributo por el cual ordenar los agentes.
   * @param {string} [params.label] - Etiqueta para filtrar los agentes.
   * @returns {Promise<void>}
   */
  get: async ({ commit, state }, { page = 1, sortAttr, label }) => {
    commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AgentAPI.get(page, sortAttr, label);
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      commit(types.default.SET_AGENTS, response.data.payload);
      commit(types.default.SET_AGENT_META, response.data.meta);

      commit(
        types.default.SET_AGENT_SORT_ORDER,
        response.data.payload.map(agent => agent.id)
      );
    } catch (error) {
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },

  /**
   * Busca agentes basados en un término de búsqueda y paginación.
   * @param {Object} context - El contexto del módulo que contiene `commit` y `state`.
   * @param {Object} params - Los parámetros para la búsqueda.
   * @param {string} params.search - El término de búsqueda.
   * @param {number} [params.page=1] - El número de página a obtener.
   * @param {string} [params.sortAttr] - Atributo por el cual ordenar los agentes.
   * @param {string} [params.label] - Etiqueta para filtrar los agentes.
   * @returns {Promise<void>}
   */
  search: async ({ commit, state }, { search, page = 1, sortAttr, label }) => {
    commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AgentAPI.search(search, page, sortAttr, label);
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      commit(types.default.SET_AGENTS, response.data.payload);
      commit(types.default.SET_AGENT_META, response.data.meta);
      // Update sortOrder based on the order of agents in the response
      commit(
        types.default.SET_AGENT_SORT_ORDER,
        response.data.payload.map(agent => agent.id)
      );
    } catch (error) {
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },

  /**
   * Crea un nuevo agente y actualiza el estado.
   * @param {Object} context - El contexto del módulo que contiene `commit` y `state`.
   * @param {Object} agentInfo - Información del agente a crear.
   * @returns {Promise<void>}
   */
  create: async ({ commit, state }, agentInfo) => {
    commit(types.default.SET_AGENT_CREATING_STATUS, true);
    try {
      const response = await AgentAPI.create(agentInfo);
      commit(types.default.ADD_AGENT, response.data);

      // Increment the count of total agents
      commit(types.default.SET_AGENT_META, {
        ...state.meta,
        count: state.meta.count + 1, // Update the count
      });

      commit(types.default.SET_AGENT_CREATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_CREATING_STATUS, false);
      throw error;
    }
  },

  /**
   * Actualiza la información de un agente existente.
   * @param {Object} context - El contexto del módulo que contiene `commit`.
   * @param {Object} params - Los parámetros para la actualización.
   * @param {string} params.id - El ID del agente a actualizar.
   * @param {Object} params.agentParams - Los parámetros del agente a actualizar.
   * @returns {Promise<void>}
   */
  update: async ({ commit }, { id, ...agentParams }) => {
    commit(types.default.SET_AGENT_UPDATING_STATUS, true);
    try {
      const response = await AgentAPI.update(id, agentParams);
      commit(types.default.EDIT_AGENT, response.data);
      commit(types.default.SET_AGENT_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_UPDATING_STATUS, false);
      throw error;
    }
  },

  /**
   * Actualiza la presencia de un agente específico.
   * @param {Object} context - El contexto del módulo que contiene `commit`.
   * @param {Object} params - Los parámetros para la actualización de presencia.
   * @param {string} params.id - El ID del agente cuya presencia se actualizará.
   * @param {string} params.availabilityStatus - El nuevo estado de disponibilidad del agente.
   */
  updateSingleAgentPresence: ({ commit }, { id, availabilityStatus }) => {
    commit(types.default.UPDATE_SINGLE_AGENT_PRESENCE, {
      id,
      availabilityStatus,
    });
  },

  /**
   * Actualiza la presencia de múltiples agentes.
   * @param {Object} context - El contexto del módulo que contiene `commit` y `state`.
   * @param {Object} data - Los datos para la actualización de presencia.
   * @returns {Promise<void>}
   */
  updatePresence: async ({ commit, state }, data) => {
    commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AgentAPI.getAgentsWithPresence(data);
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      commit(types.default.SET_AGENTS, response.data.payload);
      commit(types.default.SET_AGENT_META, response.data.meta);
      commit(
        types.default.SET_AGENT_SORT_ORDER,
        response.data.payload.map(agent => agent.id)
      );
    } catch (error) {
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },

  /**
   * Elimina un agente por su ID.
   * @param {Object} context - El contexto del módulo que contiene `commit` y `state`.
   * @param {string} agentId - El ID del agente a eliminar.
   * @returns {Promise<void>}
   */
  delete: async ({ commit, state }, agentId) => {
    commit(types.default.SET_AGENT_DELETING_STATUS, true);
    try {
      await AgentAPI.delete(agentId);
      commit(types.default.DELETE_AGENT, agentId);

      // Decrement the count of total agents
      let newPage = state.meta.currentPage;
      const totalPages = Math.ceil(
        (state.meta.count - 1) / state.meta.pageSize
      );

      if (newPage > totalPages) {
        newPage = totalPages; // Adjust the page if we're over the limit
      }

      commit(types.default.SET_AGENT_META, {
        ...state.meta,
        count: state.meta.count - 1, // Update the count
        currentPage: newPage, // Adjust the page
      });

      commit(types.default.SET_AGENT_DELETING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_DELETING_STATUS, false);
      throw new Error(error);
    }
  },

  /**
   * Filtra agentes según los criterios especificados.
   * @param {Object} context - El contexto del módulo que contiene `commit`.
   * @param {Object} params - Los parámetros de filtrado.
   * @param {Object} params.queryPayload - Los criterios de filtrado para la solicitud.
   * @returns {Promise<void>}
   */
  filter: async ({ commit }, { queryPayload }) => {
    commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AgentAPI.filter(queryPayload);
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      commit(types.default.SET_AGENTS, response.data.payload);
      commit(types.default.SET_AGENT_META, response.data.meta);
      commit(
        types.default.SET_AGENT_SORT_ORDER,
        response.data.payload.map(agent => agent.id)
      );
      commit(
        types.default.SET_APPLIED_AGENT_FILTERS,
        response.data.appliedFilters
      );
    } catch (error) {
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },

  /**
   * Limpia los filtros aplicados a los agentes.
   * @param {Object} context - El contexto del módulo que contiene `commit`.
   * @returns {Promise<void>}
   */
  clearAgentFilters: async ({ commit }) => {
    commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      commit(types.default.SET_APPLIED_AGENT_FILTERS, []);
      commit(types.default.SET_AGENT_META, { currentPage: 1, count: 0 });
      commit(types.default.SET_AGENTS, []);
    } catch (error) {
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },
};

export const mutations = {
  // Mutations for agent management
  [types.default.SET_AGENT_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },

  [types.default.SET_AGENT_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },

  [types.default.SET_AGENT_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },

  [types.default.SET_AGENT_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },

  // Set agents and manage their records
  [types.default.SET_AGENTS]($state, agents) {
    const sortOrder = agents.map(agent => {
      Vue.set($state.records, agent.id, agent);
      return agent.id;
    });
    $state.sortOrder = sortOrder;
  },

  [types.default.ADD_AGENT]($state, agent) {
    Vue.set($state.records, agent.id, agent);
    $state.sortOrder.push(agent.id);
  },

  [types.default.EDIT_AGENT]($state, agent) {
    Vue.set($state.records, agent.id, agent);
  },

  [types.default.DELETE_AGENT]($state, agentId) {
    const index = $state.sortOrder.findIndex(id => id === agentId);
    if (index !== -1) {
      $state.sortOrder.splice(index, 1);
    }
    delete $state.records[agentId];
  },

  // Update presence status for agents
  [types.default.UPDATE_AGENTS_PRESENCE]($state, data) {
    Object.values($state.records).forEach(element => {
      const availabilityStatus = data[element.id];
      if (availabilityStatus) {
        $state.records[element.id].availability_status = availabilityStatus;
      } else {
        delete $state.records[element.id].availability_status;
      }
    });
  },

  [types.default.UPDATE_SINGLE_AGENT_PRESENCE](
    $state,
    { id, availabilityStatus }
  ) {
    if (availabilityStatus) {
      $state.records[id].availability_status = availabilityStatus;
    } else {
      delete $state.records[id].availability_status;
    }
  },

  // Manage agent metadata and applied filters
  [types.default.SET_AGENT_META]($state, meta) {
    $state.meta = meta;
  },

  [types.default.SET_APPLIED_AGENT_FILTERS]($state, filters) {
    $state.appliedFilters = filters;
  },

  [types.default.SET_AGENT_SORT_ORDER]($state, sortOrder) {
    $state.sortOrder = sortOrder;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
